import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCs6OAv0hwDJCGRS8CGldUnG2HZqWZ0DYc",
  authDomain: "trvl-dev-400414.firebaseapp.com",
  projectId: "trvl-dev-400414",
  storageBucket: "trvl-dev-400414.appspot.com",
  messagingSenderId: "179958297430",
  appId: "1:179958297430:web:0e91e6a9b612e088fe5cf7",
  measurementId: "G-6BDDWV1RDR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
