import './App.css';
import logo from './img/logo-transparent.png'

function App() {
  return (
    <div style={{ backgroundImage: "img/eva-darron-oCdVtGFeDC0-unsplash.jpg"}}>
      <header className="App-header">
        <img src={logo} className='logo-container' />
        <p className='App-content'>
          Embrace the Wanderlust.
          <br/>
          <p style={{ fontSize: "14px" }}>Coming soon.</p>
        </p>
        <div className='App-footer wrap'>
          <p>
            TRVL Ltd - Registered Office: 85 Great Portland Street, First Floor, London, England, W1W 7LT
            <br/>
            Registered Number: 15114814. Registered in England & Wales.
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
